<template>
    <div 
        v-if="online && swUpdate" 
        class="sw_update_banner">
        <p>Необходимо обновить приложение</p>
        <a-button 
            ghost
            icon="sync"
            @click="update()">
            Обновить
        </a-button>
    </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    computed: {
        ...mapState({
            swUpdate: state => state.swUpdate,
            swRegistration: state => state.swRegistration,
            online: state => state.online,
        })
    },
    data() {
        return {
            refreshing: false
        }
    },
    created() {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return
            this.refreshing = true
            location.reload()
        })
    },
    methods: {
        async update() {
            try {
                if (!this.swRegistration || !this.swRegistration.waiting) return
                await this.swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sw_update_banner{
    position: fixed;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    bottom: 20px;
    right: 20px;
    text-align: center;
    p{
        font-size: 16px;
        margin-bottom: 10px;
    }
}
</style>
