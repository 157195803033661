export default {
    computed: {
        deferredPrompt() {
            return this.$store.state.deferredPrompt
        },
        pwaBanner() {
            if(this.deferredPrompt)
                return () => import(`./PWABanner`)
            else
                return null
        },
        support() {
            if(this.appConfig?.header_setting?.support) {
                return () => import('./Support.vue')
            } else
                return null
        },
        notification() {
            if(this.appConfig?.header_setting?.notification)
                return () => import(`./Notification`)
            else
                return null
        }
    }
}