export default {
    computed: {
        sidebarTemplate() {
            return () => import(`../components/Sidebar/MiniAside/index.vue`)
        },
        asideWidth() {
            if(this.config?.aside_setting?.width) {
                return this.config.aside_setting.width
            } else
                return 300
        }
    }
}